* {
  font-family: "Ubuntu", system-ui, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* font-family: Courier; */
}

:root {
  scroll-behavior: smooth;
}

.HoverButton:hover {
  background-color: var(--bs-secondary-bg) !important;
}

.HoverButtonTertiary:hover {
  background-color: var(--bs-tertiary-bg) !important;
}
